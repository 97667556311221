import React from 'react';

const mockPosts = [
  {
    id: 1,
    image: 'https://images.unsplash.com/photo-1517248135467-4c7edcad34c4',
    username: '@coffeelover',
    location: 'Cozy Corner Café',
    likes: 234,
    caption: 'Perfect meetpoint with friends! ☕️ #meetpoint #coffee'
  },
  {
    id: 2,
    image: 'https://images.unsplash.com/photo-1552566626-52f8b828add9',
    username: '@foodie_friends',
    location: 'The Garden Restaurant',
    likes: 456,
    caption: 'Found our new favorite spot 🍽️ #meetpoint #foodie'
  },
  {
    id: 3,
    image: 'https://images.unsplash.com/photo-1529156069898-49953e39b3ac',
    username: '@meetup_crew',
    location: 'Urban Rooftop Bar',
    likes: 789,
    caption: 'Best views for our weekly catchup 🌆 #meetpoint #friends'
  }
];

export default function InstagramFeed() {
  return (
    <div className="glass-card p-8">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-3">
          <span className="text-3xl">📸</span>
          <h2 className="text-2xl font-bold text-brand-red">
            #meetpoint
          </h2>
        </div>
        <a
          href="https://instagram.com/explore/tags/meetpoint"
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm px-4 py-2 rounded-xl bg-brand-red/10 text-brand-red 
                   hover:bg-brand-red/20 transition-all duration-300 flex items-center gap-2"
        >
          Share yours
          <span className="text-lg">📤</span>
        </a>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
        {mockPosts.map(post => (
          <div 
            key={post.id}
            className="group relative aspect-square rounded-xl overflow-hidden 
                     hover:scale-[1.02] transition-all duration-300"
          >
            <img
              src={post.image}
              alt={post.caption}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 
                          transition-opacity duration-300 p-4 flex flex-col justify-end">
              <p className="text-white font-medium">{post.username}</p>
              <p className="text-white/80 text-sm">{post.location}</p>
              <div className="flex items-center gap-2 mt-2">
                <span className="text-red-500">❤️</span>
                <span className="text-white text-sm">{post.likes}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}