import React, { useState } from 'react';

export default function ShareButton({ venue }) {
  const [showCopied, setShowCopied] = useState(false);

  const formatShareMessage = (venue) => {
    let message = `Let's meet at ${venue.name}! 📍\n`;
    if (venue.vicinity) {
      message += `${venue.vicinity}\n\n`;
    }
    if (venue.distances && venue.distances.length > 0) {
      venue.distances.forEach(distance => {
        message += `${distance.from}: ${distance.text}\n`;
      });
    }
    if (venue.rating) {
      message += `\nRating: ${venue.rating} ⭐`;
    }
    return message;
  };

  const handleShare = async () => {
    const text = formatShareMessage(venue);
    
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Meetpoint Suggestion',
          text: text,
        });
      } catch (error) {
        console.log('Error sharing:', error);
        fallbackShare(text);
      }
    } else {
      fallbackShare(text);
    }
  };

  const fallbackShare = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setShowCopied(true);
      setTimeout(() => setShowCopied(false), 2000);
    } catch (error) {
      console.error('Failed to copy:', error);
    }
  };

  return (
    <div className="relative">
      <button
        onClick={handleShare}
        className="mt-4 w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors flex items-center justify-center gap-2"
      >
        <span>Share</span>
        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
        </svg>
      </button>
      {showCopied && (
        <div className="absolute -top-8 left-1/2 -translate-x-1/2 bg-neutral-800 text-white
                      px-3 py-1 rounded-lg text-sm whitespace-nowrap animate-fade-in">
          Copied to clipboard! 📋
        </div>
      )}
    </div>
  );
}