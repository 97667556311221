import React, { useState, useEffect } from 'react';

const quotes = [
  { text: "The perfect spot is where friends connect ✨", author: "MeetPoint" },
  { text: "Good conversations flow better over great coffee ☕️", author: "Café Wisdom" },
  { text: "The best memories start with 'Let's meet at...' 🌟", author: "Friend Chronicles" },
  { text: "Distance is just a number when friendship is the destination 🗺️", author: "MeetPoint" },
  { text: "Every great story begins at the perfect meeting spot 📍", author: "Social Tales" },
  { text: "Where friends gather, magic happens ✨", author: "Community Wisdom" },
  { text: "Life's best moments happen halfway between hello and goodbye 🤝", author: "MeetPoint" },
  { text: "The journey to meet is part of the adventure 🌈", author: "Wanderlust Weekly" },
  { text: "When friends meet, ordinary places become extraordinary ⭐️", author: "Social Wisdom" },
  { text: "Finding the perfect spot is an art, meeting there is pure joy 🎨", author: "MeetPoint" },
  { text: "Coffee and friends make the perfect blend ☕🤝", author: "Café Chronicles" },
  { text: "Meet me where the laughter never ends 💫", author: "Happy Moments" },
  { text: "Memories are made at the meeting point of hearts ❤️", author: "MeetPoint" },
  { text: "Good vibes and great friends make the perfect day 🌞", author: "Social Energy" },
  { text: "The shortest distance between two friends is a smile 😊", author: "Friend Chronicles" },
  { text: "A perfect spot is where moments turn into memories 📸", author: "MeetPoint" },
  { text: "Happiness is meeting your people at your place ✨", author: "Community Wisdom" },
  { text: "The best kind of therapy is meeting with friends 💬", author: "Café Wisdom" },
  { text: "Time spent with friends is time well spent ⏳", author: "Social Tales" },
  { text: "Let’s meet where dreams are brewed ☕️", author: "Café Dreams" },
  { text: "Every connection begins with a simple hello 👋", author: "MeetPoint" },
  { text: "Friendship fuels the soul like coffee fuels the day ☀️", author: "Café Chronicles" },
  { text: "Meet where the air smells like freshly brewed dreams ☕", author: "Café Aromas" },
  { text: "Home is where your people meet 🏠", author: "Social Wisdom" },
  { text: "Every gathering is an opportunity for magic 🌟", author: "MeetPoint" },
  { text: "Friendship is the bridge between two hearts 🌉", author: "Community Wisdom" },
  { text: "Meeting friends is the perfect pause button ⏸️", author: "Social Tales" },
  { text: "Shared moments make life extraordinary 💫", author: "Friend Chronicles" },
  { text: "Where friends gather, memories are created 📝", author: "MeetPoint" },
  { text: "Cheers to finding the perfect meeting place 🥂", author: "Social Spirits" },
  { text: "Conversations thrive in cozy corners 🛋️", author: "Café Wisdom" },
  { text: "Life’s simplest joys happen at shared tables 🍴", author: "MeetPoint" },
  { text: "Happiness is finding the perfect meetup spot 😊", author: "Community Wisdom" },
  { text: "Meet me where stories begin 📖", author: "Social Chronicles" },
  { text: "Friendship starts with showing up ✨", author: "MeetPoint" },
  { text: "The perfect spot makes perfect moments 💞", author: "Social Tales" },
  { text: "Find me where the coffee is warm and the vibes are cozy ☕", author: "Café Chronicles" },
  { text: "Where the heart meets home is the perfect place ❤️", author: "Community Wisdom" },
  { text: "Distance fades where love resides 🌍", author: "MeetPoint" },
  { text: "Let’s make every meetup a celebration 🎉", author: "Social Tales" },
  { text: "Shared coffee, shared laughs, shared lives ☕💬", author: "Café Stories" },
  { text: "Gatherings are the art of connection 🎨", author: "Community Wisdom" },
  { text: "Meet where kindness and coffee flow freely ☕️", author: "Social Café" },
  { text: "Friendship brews happiness one cup at a time ☕️", author: "Café Joy" },
  { text: "A perfect meeting is one where laughter echoes 🎶", author: "Social Tales" },
  { text: "Small moments at perfect spots make the biggest memories 🌟", author: "MeetPoint" },
  { text: "The beauty of life lies in shared stories 📝", author: "Community Chronicles" },
  { text: "Every meetup deserves the perfect vibe ✨", author: "MeetPoint" },
  { text: "Let’s meet where time stands still 🕰️", author: "Social Wisdom" },
  { text: "A great meetup is the start of something beautiful 🌸", author: "Café Wisdom" }
];

export default function QuoteCard() {
  const [currentQuote, setCurrentQuote] = useState(0);
  const [displayText, setDisplayText] = useState('');
  const [isRevealing, setIsRevealing] = useState(true);

  // Initialize with random quote
  useEffect(() => {
    setCurrentQuote(Math.floor(Math.random() * quotes.length));
  }, []);

  // Handle quote rotation every 30 seconds
  useEffect(() => {
    const rotationTimer = setInterval(() => {
      setCurrentQuote((prev) => (prev + 1) % quotes.length);
      setIsRevealing(true);
      setDisplayText('');
    }, 30000);
    return () => clearInterval(rotationTimer);
  }, []);

  // Handle scribble reveal effect
  useEffect(() => {
    if (!isRevealing) return;

    const text = quotes[currentQuote].text;
    const revealSteps = 3; // Number of reveal iterations
    let currentStep = 0;

    const revealTimer = setInterval(() => {
      if (currentStep < revealSteps) {
        // Create scrambled text
        const scrambledText = text
          .split('')
          .map((char, idx) => {
            if (currentStep === revealSteps - 1) return char;
            if (char === ' ') return ' ';
            if (Math.random() < (currentStep + 1) / revealSteps) return char;
            return '~#@$%^&*'[Math.floor(Math.random() * 8)];
          })
          .join('');
        
        setDisplayText(scrambledText);
        currentStep++;
      } else {
        setDisplayText(text);
        setIsRevealing(false);
        clearInterval(revealTimer);
      }
    }, 100);

    return () => clearInterval(revealTimer);
  }, [currentQuote, isRevealing]);

  return (
    <div className="glass-card p-8 h-full flex flex-col">
      <div className="flex items-center gap-3 mb-8">
        <span className="text-3xl">💭</span>
        <h2 className="text-2xl font-bold bg-gradient-to-r from-purple-600 via-pink-500 to-orange-500 text-transparent bg-clip-text">
          Daily Inspiration
        </h2>
      </div>
      
      <div className="flex-1 flex flex-col justify-center space-y-6">
        <p className={`text-xl md:text-2xl text-gray-700 leading-relaxed font-mono transition-all duration-200 ${
          isRevealing ? 'blur-[0.5px]' : ''
        }`}>
          {displayText}
        </p>
        <p className="text-base text-gray-500 italic">
          — {quotes[currentQuote].author}
        </p>
      </div>
    </div>
  );
}
