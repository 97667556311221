// Move this to the top level of the file, after the imports
const processPlacePhotos = (place) => {
  if (!place.photos || !Array.isArray(place.photos)) return [];
  
  return place.photos.map(photo => {
    try {
      // Get the photo_reference from the photo object
      const photoReference = photo.photo_reference;
      if (!photoReference) return null;

      // Use the legacy Places Photo API endpoint
      return {
        url: `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${photoReference}&key=${import.meta.env.VITE_GOOGLE_MAPS_API_KEY}`
      };
    } catch (error) {
      console.error('Error processing photo:', error);
      return null;
    }
  }).filter(Boolean);
};

// Single global instance management
let googleMapsPromise = null;

export const initGoogleMaps = () => {
  if (googleMapsPromise) {
    return googleMapsPromise;
  }

  googleMapsPromise = new Promise((resolve, reject) => {
    try {
      // Check if already loaded
      if (window.google?.maps?.places) {
        resolve(window.google.maps);
        return;
      }

      window.initGoogleMapsCallback = () => {
        if (window.google?.maps?.places) {
          resolve(window.google.maps);
        } else {
          reject(new Error('Places library not loaded'));
        }
        delete window.initGoogleMapsCallback;
      };

      // Load the script
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${import.meta.env.VITE_GOOGLE_MAPS_API_KEY}&libraries=places,geometry&callback=initGoogleMapsCallback`;
      script.async = true;
      script.onerror = (error) => {
        reject(new Error('Failed to load Google Maps script'));
        delete window.initGoogleMapsCallback;
      };

      document.head.appendChild(script);
    } catch (error) {
      reject(error);
    }
  });

  return googleMapsPromise;
};

// Modified getPlacesService to ensure we get a working service
const getPlacesService = async () => {
  const maps = await initGoogleMaps();
  
  // Create a temporary map div if it doesn't exist
  let mapDiv = document.getElementById('places-service-map');
  if (!mapDiv) {
    mapDiv = document.createElement('div');
    mapDiv.id = 'places-service-map';
    mapDiv.style.height = '100px';
    mapDiv.style.width = '100px';
    mapDiv.style.visibility = 'hidden';
    mapDiv.style.position = 'absolute';
    document.body.appendChild(mapDiv);
  }
  
  const map = new maps.Map(mapDiv, {
    center: { lat: 0, lng: 0 },
    zoom: 2
  });
  
  return new maps.places.PlacesService(map);
};

export async function findMeetingSpots(locations) {
  try {
    const service = await getPlacesService();
    
    const midpoint = locations.reduce((acc, loc) => ({
      lat: acc.lat + Number(loc.lat) / locations.length,
      lng: acc.lng + Number(loc.lon) / locations.length
    }), { lat: 0, lng: 0 });

    let radius = 1500;
    if (locations.length > 1) {
      radius = google.maps.geometry.spherical.computeDistanceBetween(
        new google.maps.LatLng(locations[0].lat, locations[0].lon),
        new google.maps.LatLng(locations[1].lat, locations[1].lon)
      ) / 2;
    }

    return new Promise((resolve, reject) => {
      service.nearbySearch({
        location: new google.maps.LatLng(midpoint.lat, midpoint.lng),
        radius: Math.min(radius, 5000),
        type: ['restaurant', 'cafe'],
        openNow: true,
        rankBy: google.maps.places.RankBy.RATING,
        fields: ['place_id', 'name', 'geometry', 'rating', 'types', 'vicinity', 'price_level', 'photos.photo_reference']
      }, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && results) {
          const places = results.map(place => ({
            id: place.place_id,
            name: place.name,
            lat: place.geometry.location.lat(),
            lon: place.geometry.location.lng(),
            rating: place.rating || 0,
            types: place.types || [],
            vicinity: place.vicinity,
            price_level: place.price_level,
            photos: processPlacePhotos(place)
          }));
          resolve(places);
        } else {
          reject(new Error(`Places API error: ${status}`));
        }
      });
    });
  } catch (error) {
    console.error('Error finding meeting spots:', error);
    throw error;
  }
}

export async function searchNearbyPlaces(location, radius = 1000, type = 'restaurant') {
  return new Promise((resolve, reject) => {
    try {
      const service = getPlacesService();
      
      const request = {
        location: new google.maps.LatLng(location.lat, location.lon),
        radius: radius,
        type: type,
        openNow: true,
        fields: ['place_id', 'name', 'geometry', 'rating', 'types', 'vicinity', 'price_level', 'photos']
      };

      service.nearbySearch(request, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && results) {
          const places = results.map(place => ({
            id: place.place_id,
            name: place.name,
            lat: place.geometry.location.lat(),
            lon: place.geometry.location.lng(),
            rating: place.rating || 0,
            types: place.types || [],
            vicinity: place.vicinity,
            price_level: place.price_level,
            photos: processPlacePhotos(place)
          }));
          resolve(places);
        } else {
          reject(new Error(`Places API error: ${status}`));
        }
      });
    } catch (error) {
      reject(error);
    }
  });
}

export async function getPlaceDetails(placeId) {
  return new Promise((resolve, reject) => {
    try {
      const service = getPlacesService();
      
      const request = {
        placeId: placeId,
        fields: ['name', 'rating', 'formatted_address', 'geometry', 'photos', 
                'opening_hours', 'website', 'formatted_phone_number', 'reviews', 
                'price_level', 'types']
      };

      service.getDetails(request, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve({
            id: place.place_id,
            name: place.name,
            lat: place.geometry.location.lat(),
            lon: place.geometry.location.lng(),
            rating: place.rating || 0,
            address: place.formatted_address,
            photos: processPlacePhotos(place),
            website: place.website,
            phone: place.formatted_phone_number,
            types: place.types || [],
            price_level: place.price_level,
            opening_hours: place.opening_hours
          });
        } else {
          reject(new Error(`Failed to get place details: ${status}`));
        }
      });
    } catch (error) {
      reject(error);
    }
  });
}

// Simplify the getPhotoUrl function
export const getPhotoUrl = (photo) => {
  if (!photo?.url) {
    console.warn('No photo URL available, using placeholder');
    return '/restaurant-placeholder.jpg';
  }
  
  // Return the actual photo URL
  return photo.url;
};

// Update Google Maps URL function
export const getGoogleMapsUrl = (place) => {
  if (!place) return '#';
  
  const placeId = place.place_id || place.id;
  if (placeId) {
    // Use the official Google Maps Place URL format
    return `https://www.google.com/maps/place/?q=place_id:${placeId}`;
  }
  
  // Fallback to search if no place ID
  if (place.name && place.vicinity) {
    return `https://www.google.com/maps/search/${encodeURIComponent(place.name + ' ' + place.vicinity)}`;
  }
  
  return '#';
};