import { useEffect, useCallback, useState, useRef } from 'react';
import { GoogleMap } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '100%',
  minHeight: '400px'
};

// Los Angeles coordinates
const defaultCenter = {
  lat: 34.0522,
  lng: -118.2437
};

const defaultOptions = {
  disableDefaultUI: false,
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: true,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: true,
  styles: [
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [{ visibility: "off" }]
    }
  ]
};

export default function MapController({ center, locations, meetingSpots }) {
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [map, setMap] = useState(null);
  const markersRef = useRef([]);
  const infoWindowRef = useRef(null);

  const fitBounds = useCallback((map) => {
    if (!map) return;

    const bounds = new google.maps.LatLngBounds();
    let hasValidPoints = false;
    
    // Add location markers to bounds
    locations?.forEach(loc => {
      if (loc.latitude && loc.longitude) {
        bounds.extend({
          lat: Number(loc.latitude),
          lng: Number(loc.longitude)
        });
        hasValidPoints = true;
      }
    });

    // Add meeting spots to bounds
    meetingSpots?.forEach(spot => {
      if (spot.lat && spot.lon) {
        bounds.extend({
          lat: Number(spot.lat),
          lng: Number(spot.lon)
        });
        hasValidPoints = true;
      }
    });

    // If we have points, fit the map to them
    if (hasValidPoints) {
      map.fitBounds(bounds, { 
        padding: { 
          top: 50, 
          right: 50, 
          bottom: 50, 
          left: 50 
        } 
      });
      
      // Prevent too much zoom on single point or close points
      const listener = map.addListener('idle', () => {
        if (map.getZoom() > 15) {
          map.setZoom(15);
        }
        google.maps.event.removeListener(listener);
      });
    } else {
      // If no valid points, center on provided center or default to LA
      const centerPoint = center && Array.isArray(center) && center.length === 2
        ? { lat: center[0], lng: center[1] }
        : defaultCenter;
      
      map.setCenter(centerPoint);
      map.setZoom(11);
    }
  }, [center, locations, meetingSpots]);

  // Handle initial map load
  const onLoad = useCallback((map) => {
    setMap(map);
    
    // Set initial center and zoom
    if (!locations?.length && !meetingSpots?.length) {
      map.setCenter(defaultCenter);
      map.setZoom(11);
    } else {
      fitBounds(map);
    }
  }, [fitBounds, locations, meetingSpots]);

  // Update map when data changes
  useEffect(() => {
    if (map) {
      fitBounds(map);
    }
  }, [map, locations, meetingSpots, fitBounds]);

  // Handle markers
  useEffect(() => {
    if (!map) return;

    // Clear existing markers
    markersRef.current.forEach(marker => {
      if (marker) marker.setMap(null);
    });
    markersRef.current = [];

    try {
      // Add location markers
      locations?.forEach((location, index) => {
        if (!location.latitude || !location.longitude) return;

        // Create regular marker for locations
        const marker = new google.maps.Marker({
          map,
          position: {
            lat: Number(location.latitude),
            lng: Number(location.longitude)
          },
          title: location.name || `Location ${index + 1}`,
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 10,
            fillColor: '#EA4335',
            fillOpacity: 1,
            strokeWeight: 2,
            strokeColor: '#FFFFFF'
          }
        });

        marker.addListener('click', () => {
          if (infoWindowRef.current) {
            infoWindowRef.current.close();
          }
          setSelectedPlace(location);
        });
        markersRef.current.push(marker);
      });

      // Add meeting spot markers
      meetingSpots?.forEach((spot, index) => {
        if (!spot.lat || !spot.lon) return;

        // Create regular marker for places
        const marker = new google.maps.Marker({
          map,
          position: {
            lat: Number(spot.lat),
            lng: Number(spot.lon)
          },
          title: spot.name,
          icon: {
            url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(`
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32">
                <circle cx="12" cy="12" r="10" fill="#4285F4" stroke="white" stroke-width="2"/>
                <text x="12" y="16" text-anchor="middle" fill="white" font-size="12px" font-family="Arial">
                  ${spot.category === 'cafe' ? '☕' : '🍽️'}
                </text>
              </svg>
            `)}`,
            scaledSize: new google.maps.Size(32, 32)
          }
        });

        marker.addListener('click', () => {
          if (infoWindowRef.current) {
            infoWindowRef.current.close();
          }
          setSelectedPlace(spot);
        });
        markersRef.current.push(marker);
      });
    } catch (error) {
      console.error('Error creating markers:', error);
    }
  }, [map, locations, meetingSpots]);

  // Handle InfoWindow
  useEffect(() => {
    if (!map || !selectedPlace) return;

    // Close existing InfoWindow
    if (infoWindowRef.current) {
      infoWindowRef.current.close();
    }

    try {
      const content = document.createElement('div');
      content.className = 'info-window';
      content.innerHTML = `
        <div style="max-width: 300px; padding: 8px;">
          <h3 style="font-weight: bold; margin-bottom: 8px;">${selectedPlace.name || 'Location'}</h3>
          ${selectedPlace.photos?.[0] ? `
            <img src="${selectedPlace.photos[0]}" 
                 alt="${selectedPlace.name}" 
                 style="width: 100%; height: 150px; object-fit: cover; margin-bottom: 8px; border-radius: 4px;">
          ` : ''}
          ${selectedPlace.rating ? `
            <div style="margin-bottom: 4px;">
              Rating: ${selectedPlace.rating} ⭐ (${selectedPlace.user_ratings_total} reviews)
            </div>
          ` : ''}
          ${selectedPlace.distances ? `
            <div style="margin: 8px 0;">
              ${selectedPlace.distances.map(d => `
                <div style="margin-bottom: 4px;">📍 ${d.from}: ${d.text}</div>
              `).join('')}
            </div>
          ` : ''}
          ${selectedPlace.vicinity ? `
            <div style="margin-top: 4px;">📍 ${selectedPlace.vicinity}</div>
          ` : ''}
        </div>
      `;

      const infoWindow = new google.maps.InfoWindow({
        content,
        pixelOffset: new google.maps.Size(0, -30)
      });

      const position = {
        lat: Number(selectedPlace.latitude || selectedPlace.lat),
        lng: Number(selectedPlace.longitude || selectedPlace.lon)
      };

      infoWindow.setPosition(position);
      infoWindow.open(map);
      infoWindowRef.current = infoWindow;

      // Add click listener to close InfoWindow when clicking on map
      const mapClickListener = map.addListener('click', () => {
        infoWindow.close();
        setSelectedPlace(null);
      });

      return () => {
        infoWindow.close();
        google.maps.event.removeListener(mapClickListener);
      };
    } catch (error) {
      console.error('Error creating info window:', error);
    }
  }, [selectedPlace, map]);

  return (
    <div style={{ width: '100%', height: '100%', minHeight: '400px' }}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        options={defaultOptions}
        onLoad={onLoad}
      />
    </div>
  );
} 