import { initGoogleMaps } from '../services/placesService';

const calculateDistance = (point1, point2) => {
  const R = 3959; // Earth's radius in miles
  const lat1 = point1.lat * Math.PI / 180;
  const lat2 = point2.lat * Math.PI / 180;
  const deltaLat = (point2.lat - point1.lat) * Math.PI / 180;
  const deltaLon = (point2.lon - point1.lon) * Math.PI / 180;

  const a = Math.sin(deltaLat/2) * Math.sin(deltaLat/2) +
           Math.cos(lat1) * Math.cos(lat2) *
           Math.sin(deltaLon/2) * Math.sin(deltaLon/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

  return R * c; // Distance in miles
};

export const findMeetingSpots = async (locations, filters = { types: [], minRating: 3 }) => {
  try {
    if (!locations || locations.length === 0) return [];

    await initGoogleMaps();

    const midpoint = locations.reduce(
      (acc, loc) => ({
        lat: acc.lat + Number(loc.lat) / locations.length,
        lng: acc.lng + Number(loc.lon) / locations.length
      }),
      { lat: 0, lng: 0 }
    );

    const mapDiv = document.createElement('div');
    document.body.appendChild(mapDiv);
    const map = new google.maps.Map(mapDiv, { center: midpoint, zoom: 13 });
    const service = new google.maps.places.PlacesService(map);

    try {
      const results = await new Promise((resolve, reject) => {
        service.nearbySearch({
          location: new google.maps.LatLng(midpoint.lat, midpoint.lng),
          rankBy: google.maps.places.RankBy.DISTANCE,
          type: ['restaurant', 'cafe'],
          keyword: filters.types.join(' OR ') || 'restaurant OR cafe'
        }, (results, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            resolve(results);
          } else {
            reject(new Error(`Places search failed: ${status}`));
          }
        });
      });

      const filteredResults = results
        .filter(place => place.rating >= filters.minRating)
        .slice(0, 10);

      const detailedPlaces = await Promise.all(
        filteredResults.map(async (place) => {
          try {
            const details = await new Promise((resolve, reject) => {
              service.getDetails({
                placeId: place.place_id,
                fields: ['name', 'geometry', 'rating', 'user_ratings_total', 'photos', 'vicinity', 'types']
              }, (result, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                  resolve(result);
                } else {
                  reject(new Error(`Place details failed: ${status}`));
                }
              });
            });

            const distances = locations.map((loc, index) => {
              const distance = calculateDistance(
                { lat: Number(loc.lat), lon: Number(loc.lon) },
                { lat: details.geometry.location.lat(), lon: details.geometry.location.lng() }
              );
              return {
                from: loc.name || `Location ${index + 1}`,
                distance,
                text: `${distance.toFixed(1)} mi`
              };
            });

            const avgDistance = distances.reduce((sum, d) => sum + d.distance, 0) / distances.length;

            // Get photo references and URLs
            const photos = details.photos?.map(photo => ({
              url: photo.getUrl(),
              reference: photo.photo_reference,
              width: photo.width,
              height: photo.height
            })) || [];

            return {
              name: details.name,
              lat: details.geometry.location.lat(),
              lon: details.geometry.location.lng(),
              rating: details.rating,
              user_ratings_total: details.user_ratings_total,
              photos,
              vicinity: details.vicinity,
              types: details.types,
              category: details.types.includes('cafe') ? 'cafe' : 'restaurant',
              distance: avgDistance,
              distances
            };
          } catch (error) {
            console.error('Error getting place details:', error);
            return null;
          }
        })
      );

      return detailedPlaces
        .filter(place => place !== null)
        .sort((a, b) => a.distance - b.distance);

    } finally {
      document.body.removeChild(mapDiv);
    }
  } catch (error) {
    console.error('Error in findMeetingSpots:', error);
    return [];
  }
};

export async function findNearbyPlaces(location, radius = 1000) {
  return findMeetingSpots([location], {
    types: ['restaurant', 'cafe'],
    minRating: 3
  });
}

// Simplified marker creation
const createMarker = async (position, map) => {
  if (!map) return null;
  
  try {
    await initGoogleMaps();
    
    const pos = {
      lat: parseFloat(position.lat),
      lng: parseFloat(position.lon)
    };

    // Check if AdvancedMarkerElement is available
    if (window.google?.maps?.marker?.AdvancedMarkerElement) {
      return new window.google.maps.marker.AdvancedMarkerElement({
        map,
        position: pos,
        title: position.name || 'Location',
        content: new window.google.maps.marker.PinElement({
          glyph: position.types?.includes('restaurant') ? '🍽️' : '📍',
          scale: 1.2
        }).element
      });
    }

    // Fallback to regular marker
    return new window.google.maps.Marker({
      position: pos,
      map,
      title: position.name || 'Location'
    });
  } catch (error) {
    console.error('Error creating marker:', error);
    return null;
  }
};

// Simplified bounds setting
const setBounds = async (map, locations) => {
  if (!map || !locations?.length) return;
  
  try {
    await initGoogleMaps();
    const bounds = new google.maps.LatLngBounds();
    
    locations.forEach(location => {
      const lat = parseFloat(location.lat);
      const lng = parseFloat(location.lon);
      
      if (!isNaN(lat) && !isNaN(lng)) {
        bounds.extend({ lat, lng });
      }
    });
    
    if (!bounds.isEmpty()) {
      map.fitBounds(bounds, {
        padding: { top: 50, right: 50, bottom: 50, left: 50 }
      });

      // Add a small delay before adjusting zoom
      setTimeout(() => {
        const zoom = map.getZoom();
        if (zoom > 14) {
          map.setZoom(14);
        }
      }, 100);
    }
  } catch (error) {
    console.error('Error setting bounds:', error);
  }
};

// Export the functions
export { setBounds, createMarker };