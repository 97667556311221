import React from 'react';

export default function Footer() {
  const hyperProducts = [
    {
      name: 'HyperCard',
      url: 'https://hypercard.cc',
      description: 'Smart business cards for the modern professional'
    },
    {
      name: 'HyperFlow',
      url: 'https://hyperflow.cloud',
      description: 'Streamline your workflow automation'
    }
  ];

  return (
    <footer className="py-12 mt-12 border-t border-gray-100">
      <div className="max-w-7xl mx-auto text-center">
        <div className="mb-8">
          <h3 className="text-xl font-semibold mb-2 bg-gradient-to-r from-purple-600 via-pink-500 to-orange-500 text-transparent bg-clip-text">
            More from HyperTeam
          </h3>
          <p className="text-sm text-gray-600 mb-6">
            Check out our other innovative products
          </p>
          
          <div className="grid md:grid-cols-2 gap-6 max-w-2xl mx-auto">
            {hyperProducts.map((product) => (
              <a
                key={product.name}
                href={product.url}
                target="_blank"
                rel="noopener noreferrer"
                className="glass-card p-6 transition-all duration-300 hover:scale-105"
              >
                <h4 className="text-lg font-semibold mb-2 bg-gradient-to-r from-purple-600 via-pink-500 to-orange-500 text-transparent bg-clip-text">
                  {product.name}
                </h4>
                <p className="text-sm text-gray-600">
                  {product.description}
                </p>
              </a>
            ))}
          </div>
        </div>

        <div className="text-sm text-gray-500">
          <p>© {new Date().getFullYear()} HyperTeam. All rights reserved.</p>
          <p className="mt-2">
            Made with 💜 by the HyperTeam
          </p>
        </div>
      </div>
    </footer>
  );
}