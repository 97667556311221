import React from 'react';

const venueTypes = [
  { id: 'restaurant', label: 'Restaurants', icon: '🍽️' },
  { id: 'cafe', label: 'Coffee Shops', icon: '☕' },
  { id: 'bar', label: 'Bars & Lounges', icon: '🍸' },
  { id: 'park', label: 'Parks', icon: '🌳' },
  { id: 'library', label: 'Libraries', icon: '📚' }
];

const SearchFilters = ({ selectedTypes, onTypeChange }) => {
  return (
    <div className="mt-4 text-center">
      <p className="text-sm text-gray-500 mb-3">Filter by venue type</p>
      <div className="flex flex-wrap items-center justify-center gap-2">
        {venueTypes.map((type) => (
          <button
            key={type.id}
            onClick={() => onTypeChange(type.id)}
            className={`
              inline-flex items-center px-4 py-2
              rounded-full text-sm font-medium
              transition-all duration-150 ease-in-out
              ${selectedTypes.includes(type.id)
                ? 'bg-blue-500 text-white ring-2 ring-blue-300'
                : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-200'
              }
            `}
          >
            <span className="mr-2">{type.icon}</span>
            <span>{type.label}</span>
            {selectedTypes.includes(type.id) && (
              <span className="ml-1.5">✓</span>
            )}
          </button>
        ))}
      </div>
      <p className="mt-2 text-xs text-gray-400">
        Select multiple types to see more options
      </p>
    </div>
  );
};

export default SearchFilters; 