import React from 'react';

export default function Header() {
  return (
    <header className="py-12">
      <div className="flex flex-col items-center text-center space-y-4">
        <h1 className="text-5xl md:text-7xl font-black tracking-tight">
          <span className="bg-gradient-to-r from-purple-600 via-pink-500 to-orange-500 text-transparent bg-clip-text font-display">
            MeetPoint
          </span>
        </h1>
        <p className="text-lg text-gray-600 max-w-2xl">
          Enter locations, refine your preferences, and let MeetPoint handle the logistics.
        </p>
        <div className="flex items-center gap-2 text-sm">
          <span className="text-gray-500">Powered by</span>
          <span className="bg-gradient-to-r from-purple-600 via-pink-500 to-orange-500 text-transparent bg-clip-text font-medium">
            AI + Magic ✨
          </span>
        </div>
      </div>
    </header>
  );
}