import React from 'react';
import { getPhotoUrl, getGoogleMapsUrl } from '../services/placesService';
import ShareButton from './ui/ShareButton';

export default function VenueList({ venues = [], loading = false }) {
  if (loading) {
    return (
      <div className="glass-card p-8 h-full overflow-hidden">
        <div className="flex items-center gap-3 mb-8">
          <span className="text-3xl animate-pulse">✨</span>
          <h2 className="text-2xl font-bold bg-gradient-to-r from-purple-600 via-pink-500 to-orange-500 text-transparent bg-clip-text">
            Finding perfect spots...
          </h2>
        </div>
        <div className="grid gap-4">
          {[...Array(3)].map((_, i) => (
            <div key={i} className="bg-white rounded-xl shadow-sm p-4 animate-pulse">
              <div className="h-48 bg-gray-200 rounded-lg mb-4"></div>
              <div className="h-4 bg-gray-200 rounded w-3/4 mb-4"></div>
              <div className="h-4 bg-gray-200 rounded w-1/2"></div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (!venues.length) {
    return (
      <div className="glass-card p-8 h-full overflow-hidden">
        <div className="flex items-center gap-3 mb-8">
          <span className="text-3xl">🔍</span>
          <h2 className="text-2xl font-bold bg-gradient-to-r from-purple-600 via-pink-500 to-orange-500 text-transparent bg-clip-text">
            Enter locations to start
          </h2>
        </div>
      </div>
    );
  }

  return (
    <div className="glass-card p-8 h-full overflow-hidden">
      <div className="flex items-center gap-3 mb-8">
        <span className="text-3xl animate-pulse">✨</span>
        <h2 className="text-2xl font-bold bg-gradient-to-r from-purple-600 via-pink-500 to-orange-500 text-transparent bg-clip-text">
          Perfect Spots Found!
        </h2>
      </div>
      <div className="grid gap-4 overflow-y-auto max-h-[calc(800px-8rem)]">
        {venues.map((venue, index) => {
          const mapsUrl = getGoogleMapsUrl(venue);
          return (
            <div key={index} className="bg-white rounded-xl shadow-sm overflow-hidden hover:shadow-md transition-shadow duration-200">
              <div className="block">
                {venue.photos?.[0] && (
                  <div className="w-full h-48 overflow-hidden bg-gray-100">
                    <img 
                      src={venue.photos[0]?.url || '/restaurant-placeholder.jpg'}
                      alt={venue.name}
                      className="w-full h-full object-cover"
                      loading="lazy"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/restaurant-placeholder.jpg';
                      }}
                    />
                  </div>
                )}
                <div className="p-4">
                  <div className="flex items-start justify-between gap-2">
                    <h3 className="font-semibold text-lg">{venue.name}</h3>
                    <span className="text-xl">
                      {venue.category === 'cafe' ? '☕️' : '🍽️'}
                    </span>
                  </div>
                  <div className="flex items-center gap-2 mt-1 text-sm text-gray-600">
                    {venue.rating && (
                      <span className="flex items-center gap-1">
                        ⭐ {venue.rating}
                      </span>
                    )}
                  </div>
                  {venue.vicinity && (
                    <p className="text-gray-600 text-sm mt-2">{venue.vicinity}</p>
                  )}
                  {venue.distances && venue.distances.length > 0 && (
                    <div className="mt-4 space-y-2 text-sm">
                      {venue.distances.map((distance, idx) => (
                        <div key={idx} className="flex justify-between items-center text-gray-600">
                          <span>From {distance.from}:</span>
                          <span className="font-medium">{distance.text}</span>
                        </div>
                      ))}
                    </div>
                  )}
                  <ShareButton venue={venue} />
                  <a 
                    href={getGoogleMapsUrl(venue)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mt-4 w-full text-sm text-blue-600 hover:text-blue-800 flex items-center justify-center gap-2 cursor-pointer py-2 hover:bg-blue-50 rounded-lg transition-colors"
                  >
                    <span>View on Google Maps</span>
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}