import { useState } from 'react';
import { searchLocation } from '../utils/geocoding';

export default function SearchForm({ onSearch }) {
  const [locations, setLocations] = useState(['', '']);
  const [searching, setSearching] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSearching(true);

    try {
      const validLocations = locations.filter(loc => loc.trim() !== '');
      if (validLocations.length < 2) {
        throw new Error('Please enter at least two locations');
      }

      const results = await Promise.all(
        validLocations.map(location => searchLocation(location))
      );

      onSearch(results);
    } catch (error) {
      console.error('Search error:', error);
      alert(error.message);
    } finally {
      setSearching(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6 max-w-2xl mx-auto">
      <div className="grid gap-4 md:grid-cols-2">
        {locations.map((location, index) => (
          <div key={index} className="relative group backdrop-blur-xl">
            <div className="absolute inset-0 bg-gradient-to-r from-purple-500/10 via-pink-500/10 to-orange-500/10 rounded-2xl -z-10"></div>
            <input
              type="text"
              value={location}
              onChange={(e) => {
                const newLocations = [...locations];
                newLocations[index] = e.target.value;
                setLocations(newLocations);
              }}
              placeholder={`Location ${index + 1} 📍`}
              className="input-primary bg-white/70 backdrop-blur-xl"
              required
            />
            {locations.length > 2 && (
              <button
                type="button"
                onClick={() => {
                  const newLocations = locations.filter((_, i) => i !== index);
                  setLocations(newLocations);
                }}
                className="absolute right-2 top-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity btn-danger"
              >
                ✕
              </button>
            )}
          </div>
        ))}
      </div>

      <div className="flex flex-wrap gap-4 justify-center">
        {locations.length < 5 && (
          <button
            type="button"
            onClick={() => setLocations([...locations, ''])}
            className="btn-secondary group"
          >
            <span className="group-hover:scale-110 transition-transform inline-block mr-2">➕</span>
            Add Location
          </button>
        )}
        
        <button
          type="submit"
          disabled={searching}
          className="btn-primary group"
        >
          {searching ? (
            <span className="flex items-center">
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Finding Magic ✨
            </span>
          ) : (
            <>
              <span className="group-hover:scale-110 transition-transform inline-block mr-2">🎯</span>
              Find Perfect Spot
            </>
          )}
        </button>
      </div>
    </form>
  );
}