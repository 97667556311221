import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';

// Los Angeles coordinates as fallback
const DEFAULT_LOCATION = {
  lat: 34.0522,
  lon: -118.2437
};

const weatherEmojis = {
  Clear: '☀️',
  Sunny: '☀️',
  Clouds: '☁️',
  Cloudy: '☁️',
  'Partly cloudy': '⛅️',
  Rain: '🌧️',
  Snow: '🌨️',
  Thunderstorm: '⛈️',
  Drizzle: '🌦️',
  Mist: '🌫️',
  default: '🌤️'
};

export default function WeatherCard() {
  const [weather, setWeather] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const loadWeather = async (position) => {
    try {
      const response = await fetch(
        `https://api.weatherapi.com/v1/current.json?key=${import.meta.env.VITE_WEATHER_API_KEY}&q=${position.lat},${position.lon}&aqi=no`
      );
      
      if (!response.ok) {
        throw new Error('Failed to fetch weather data');
      }
      
      const data = await response.json();
      setWeather({
        temp: data.current.temp_f,
        condition: data.current.condition.text,
        description: data.current.condition.text,
        humidity: data.current.humidity,
        wind: data.current.wind_mph,
        feelsLike: data.current.feelslike_f,
        location: data.location.name
      });
      setError(null);
    } catch (err) {
      console.error('Failed to load weather:', err);
      setError('Unable to load weather information');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getLocation = () => {
      setLoading(true);
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            loadWeather({
              lat: position.coords.latitude,
              lon: position.coords.longitude
            });
          },
          (error) => {
            console.log('Geolocation error:', error);
            // Use Los Angeles as fallback
            loadWeather(DEFAULT_LOCATION);
          },
          {
            enableHighAccuracy: false,
            timeout: 10000,
            maximumAge: 300000 // 5 minutes
          }
        );
      } else {
        // Fallback if geolocation is not available
        loadWeather(DEFAULT_LOCATION);
      }
    };

    getLocation();
  }, []);

  if (loading) {
    return (
      <div className="glass-card p-8">
        <div className="animate-pulse space-y-4">
          <div className="h-8 bg-neutral-100 rounded-xl w-3/4" />
          <div className="h-24 bg-neutral-50 rounded-xl" />
        </div>
      </div>
    );
  }

  if (!weather) {
    return (
      <div className="glass-card p-6">
        <button 
          onClick={() => getLocation()}
          className="w-full py-2 text-blue-500 hover:text-blue-600"
        >
          Load Weather
        </button>
      </div>
    );
  }

  return (
    <div className="glass-card p-8">
      <div className="flex items-center gap-3 mb-6">
        <span className="text-3xl">{weatherEmojis[weather.condition] || weatherEmojis.default}</span>
        <h2 className="text-2xl font-bold text-brand-red">
          {weather.location || 'Local Weather'}
        </h2>
      </div>
      
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-4xl font-bold text-neutral-900">
              {Math.round(weather.temp)}°F
            </p>
            <p className="text-neutral-600 capitalize">
              {weather.description}
            </p>
          </div>
          <div className="text-right">
            <p className="text-sm text-neutral-500">
              {format(new Date(), 'EEEE, MMM do')}
            </p>
            <p className="text-sm text-neutral-500">
              {format(new Date(), 'h:mm a')}
            </p>
          </div>
        </div>
        
        <div className="grid grid-cols-3 gap-4 pt-4 border-t border-neutral-100">
          <div>
            <p className="text-sm text-neutral-500">Humidity</p>
            <p className="text-lg font-medium">
              {weather.humidity}% 💧
            </p>
          </div>
          <div>
            <p className="text-sm text-neutral-500">Wind</p>
            <p className="text-lg font-medium">
              {weather.wind} mph 💨
            </p>
          </div>
          <div>
            <p className="text-sm text-neutral-500">Feels like</p>
            <p className="text-lg font-medium">
              {Math.round(weather.feelsLike)}°F 🌡️
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}