import React from 'react';
import { useLoadScript } from '@react-google-maps/api';
import MapController from './map/MapController';

// Make libraries static to avoid reloading warning
const LIBRARIES = ['places'];

const Map = ({ center, zoom, locations, meetingSpots }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    libraries: LIBRARIES
  });

  if (loadError) {
    return (
      <div className="flex items-center justify-center h-full bg-red-50 text-red-600 p-4 rounded-lg">
        Error loading maps. Please check your internet connection and try again.
      </div>
    );
  }

  if (!isLoaded) {
    return (
      <div className="flex items-center justify-center h-full bg-gray-50 text-gray-600">
        Loading map...
      </div>
    );
  }

  return (
    <div className="h-full w-full">
      <MapController
        center={center}
        zoom={zoom}
        locations={locations}
        meetingSpots={meetingSpots}
      />
    </div>
  );
};

export default Map; 