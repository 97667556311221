import axios from 'axios';

export async function searchLocation(query) {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json`,
      {
        params: {
          address: query,
          key: import.meta.env.VITE_GOOGLE_MAPS_API_KEY
        }
      }
    );

    if (response.data.status !== 'OK' || !response.data.results.length) {
      throw new Error(`Location not found: ${query}`);
    }

    const location = response.data.results[0];
    const { lat, lng } = location.geometry.location;
    
    return {
      lat: lat,
      lon: lng,
      name: location.formatted_address
    };
  } catch (error) {
    console.error('Geocoding error:', error);
    throw new Error(`Failed to find location: ${query}`);
  }
}