import React from 'react';

const tips = [
  {
    icon: "🕒",
    title: "Best Meeting Times",
    description: "Off-peak hours (2-4 PM) often mean quieter venues and better seating"
  },
  {
    icon: "🎯",
    title: "Venue Selection",
    description: "Cafes are great for casual meetings, restaurants for longer discussions"
  },
  {
    icon: "💡",
    title: "Quick Tip",
    description: "Check venue reviews for wifi availability and noise levels"
  },
  {
    icon: "🌟",
    title: "Pro Move",
    description: "Save your favorite meeting spots for quick future reference"
  }
];

export default function SmartTips() {
  return (
    <div className="glass-card p-8">
      <div className="flex items-center gap-3 mb-6">
        <span className="text-3xl">💭</span>
        <h2 className="text-2xl font-bold bg-gradient-to-r from-purple-600 via-pink-500 to-orange-500 text-transparent bg-clip-text">
          Smart Meeting Tips
        </h2>
      </div>

      <div className="grid gap-4">
        {tips.map((tip, index) => (
          <div 
            key={index}
            className="flex items-start gap-3 p-3 rounded-xl hover:bg-white/50 transition-colors duration-200"
          >
            <span className="text-2xl">{tip.icon}</span>
            <div>
              <h3 className="font-medium text-gray-900">{tip.title}</h3>
              <p className="text-sm text-gray-600">{tip.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
} 