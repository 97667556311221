import { useState, useEffect } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import SearchForm from './components/SearchForm';
import VenueList from './components/VenueList';
import Map from './components/Map.jsx';
import QuoteCard from './components/quotes/QuoteCard';
import SmartTips from './components/recommendations/SmartTips';
import WeatherCard from './components/weather/WeatherCard';
import InstagramFeed from './components/social/InstagramFeed';
import { findMeetingSpots } from './utils/meetingSpots';
import { getCurrentLocation } from './utils/geolocation';
import SearchFilters from './components/SearchFilters';

export default function App() {
  const [locations, setLocations] = useState([]);
  const [meetingSpots, setMeetingSpots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [center, setCenter] = useState([32.7157, -117.1611]); // San Diego as fallback
  const [selectedTypes, setSelectedTypes] = useState(['restaurant', 'cafe']);

  const handleSearch = async (searchLocations) => {
    try {
      setLoading(true);
      setError(null);
      
      const formattedLocations = searchLocations.map(loc => ({
        latitude: Number(loc.lat),
        longitude: Number(loc.lon),
        name: loc.name || 'Location'
      }));

      const spots = await findMeetingSpots(searchLocations, {
        types: selectedTypes,
        minRating: 3
      });
      
      setLocations(formattedLocations);
      setMeetingSpots(spots);

      const midpoint = formattedLocations.reduce((acc, loc) => ([
        acc[0] + loc.latitude / formattedLocations.length,
        acc[1] + loc.longitude / formattedLocations.length
      ]), [0, 0]);

      setCenter(midpoint);
    } catch (err) {
      setError('Failed to find meeting spots. Please try again.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleTypeChange = (typeId) => {
    setSelectedTypes(prev => {
      if (prev.includes(typeId)) {
        return prev.filter(t => t !== typeId);
      }
      return [...prev, typeId];
    });
  };

  return (
    <div className="min-h-screen p-4 md:p-8 bg-neutral-50">
      <div className="max-w-7xl mx-auto">
        <Header />
        
        <div className="glass-card p-6 mb-8">
          <div className="text-center mb-8">
            <h2 className="text-2xl md:text-3xl font-bold bg-gradient-to-r from-purple-600 via-pink-500 to-orange-500 text-transparent bg-clip-text mb-2">
            Your Ultimate Spot Finder for Meetings & Hangouts
            </h2>
            <p className="text-base md:text-lg text-gray-600">
              Enter locations and customize your search
            </p>
          </div>
          
          <SearchForm onSearch={handleSearch} />
          
          <SearchFilters 
            selectedTypes={selectedTypes}
            onTypeChange={handleTypeChange}
          />
        </div>

        {error && (
          <div className="bg-red-100 border border-red-200 text-red-700 px-6 py-4 rounded-xl mb-8">
            {error}
          </div>
        )}

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mb-8">
          <div className="lg:col-span-2">
            <div className="h-[800px] map-container bg-white rounded-3xl overflow-hidden shadow-lg">
              <Map 
                center={center}
                zoom={13}
                locations={locations}
                meetingSpots={meetingSpots}
              />
            </div>
          </div>

          <div className="lg:col-span-1">
            <div className="h-[800px] overflow-auto">
              <VenueList 
                venues={meetingSpots} 
                loading={loading}
                midpoint={center}
              />
            </div>
          </div>
        </div>

        <div className="space-y-8">
          <WeatherCard />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <QuoteCard />
            <SmartTips />
          </div>
        </div>

        <div className="mt-8">
          <InstagramFeed />
        </div>

        <Footer />
      </div>
    </div>
  );
}